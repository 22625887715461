<template>
  <v-container fluid 
    class="case mb-6"
    :class="{'m-case': $vuetify.breakpoint.smAndDown}"
  >
    <v-col cols="12" class="pa-0 mt-md-4 mb-md-4 d-flex justify-start">
      <v-col cols="12" class="pa-0">
        <div 
          class="text-box text-md-h4 text-h5 font-weight-bold"
          :class="{'m-text-box mb-2' : $vuetify.breakpoint.smAndDown}"  
        >아크키친 공유주방 운영사례</div>
      </v-col>
    </v-col>
    <div 
      class=" pa-0 mt-md-6 d-flex justify-center text-box"
      :class="{'m-text-box mt-2': $vuetify.breakpoint.smAndDown}"
    >
      <v-card 
        class="pa-0 mb-4 case-box"
        :class="{'m-case-box': $vuetify.breakpoint.smAndDown}"
      > 
        <div
          v-for="list in lists"
          :key="list"
          class="ma-0 ma-0 pa-0"
        >
          <v-row 
            class="mt-8 ml-md-12 d-flex align-center"
            :class="{'ml-6': $vuetify.breakpoint.smAndDown}"
          >
            <div 
              class=" mb-4 ma-0"
            >
              <v-img
                :src="list.image"
                class="rounded-lg img-box"
                :class="{'m-img-box': $vuetify.breakpoint.smAndDown}"
              ></v-img>
            </div>
            <v-col cols="8" class="pa-0">
              <v-card-title 
                class="font-weight-bold text-decoration-underline text-md-h5"
                :class="{'text-caption pt-0' : $vuetify.breakpoint.smAndDown}"
                @click="navigateTo(list)"  
              > {{ list.title }} </v-card-title>
              <v-card-subtitle 
                class="pb-2 mb-md-16 text-md-h6"
                :class="{'text-caption mb-2' : $vuetify.breakpoint.smAndDown}"
              > {{ list.content }} </v-card-subtitle>
              <v-card-subtitle
                class="pb-9"
                v-if="$vuetify.breakpoint.mdAndUp" 
              > 작성일 :  {{ list.createDate }} </v-card-subtitle>
            </v-col>
          </v-row>
        </div> 
      </v-card>
    </div>
    <v-pagination
      v-model="page"
      :length="setPagination('length')"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    ></v-pagination>
  </v-container>
</template>
<script>
export default {
  components: {

  },
  name: 'Case',
  mounted() {
    
  },
  methods: {
    setPagination() {
      return Math.ceil(this.lists.length/8)
    },
    setCurentPage(){
    },
    navigateTo(list) {
      if(list.title){
        window.open(list.link)
      }
    }
  },
  data() {
    return {
      lists: [
        {
          image: 'https://cdn.jjn.co.kr/news/photo/202012/816155_143896_1251.jpg',
          title: '저렴한 비용으로 나만의 음식점 차려보세요',
          content: '아크키친 운영사례 내용은 이렇습니다.',
          createDate: new Date(),
          link: 'http://www.jjn.co.kr/news/articleView.html?idxno=816155',
        },
        {
          image: 'http://sjbnews.com/news/data_file/thum_li_news_702072_1.jpg',
          title: '전라북도에 공유주방 1호 탄생 아크키친 공유주방',
          content: '보증금 350~500만원, 월 임대료 35~50만원에 식당창업!',
          createDate: new Date(),
          link: 'http://sjbnews.com/news/news.php?number=702072',
        },
        {
          image: 'https://search.pstatic.net/common/?autoRotate=true&quality=95&type=w750&src=https%3A%2F%2Fldb-phinf.pstatic.net%2F20210624_15%2F1624505410524vVCTD_JPEG%2FNyHCDYJzdEp7qbvTCF8y1ytE.jpg',
          title: '창업 초기 자금 부담 덜고 운영 효율 높인 공유주방',
          content: '아크키친 운영사례 내용은 이렇습니다.',
          createDate: new Date(),
          link: 'http://www.jeollailbo.com/news/articleView.html?idxno=649368',
        },
        {
          image: 'http://sjbnews.com/news/data_file/li_news_697617_0.jpg',
          title: '중소기업·소상공인 정책수혜자 발굴에 최선',
          content: '전북중기청, 30개 지원기관·협단체와 중소기업 지원협의회',
          createDate: new Date(),
          link: 'http://sjbnews.com/news/news.php?number=697617',
        },
      ]
    }
  },
  computed: {

  }
}
</script>
<style lang="scss" scoped>
.case {
  padding-top: 110px;
  width: 1000px;  
  padding-left: 0;
  padding-right: 0;
} 
.case-box {
  width: 1000px !important; 
  height: 1020px !important;
}
.m-case-box {
  height: 500px !important; 
}
.m-case {
  padding-top: 56px;
}
.m-size {
  width: 300px !important;
}
.text-box {
  width: 1000px;
}
.m-text-box {
  width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.img-box {
  height: 200px !important;
  width: 200px !important;
}
.m-img-box {
  height: 80px !important;
  width: 80px !important;
}
</style>